<script>
import DataTable from "@/views/app/CustomComponents/DataTable/DataTable.vue";
import DatePicker from "vue2-datepicker";
import moment from 'moment'
import {getDocumentLinkWithId} from "@/libs/utils";

export default {
  name: "DasboardPaymentList",
  components: {DataTable, DatePicker},
  data: () => {
    return {
      openCalendar: false,
      calendarPeriod: moment(new Date()).format("YYYY-MM"),
      contract_list_payment: 'contracts/payment-list?document_date=',
      paymentListData: [],
      columnsRow: [
        {
          id: 'company',
          key: 'company_name',
          type: 'text',
          header: {
            name: 'Company',
          },
        },
        {
          id: 'customergroup_name',
          key: 'customergroup_name',
          type: 'text',
          header: {
            name: 'Customer group',
          },
        },
        {
          id: 'company_shortname',
          key: 'company_shortname',
          type: 'text',
          header: {
            name: 'Company Short name',
          },
        },
      ]
    }
  },
  watch: {
    calendarPeriod(newValue, oldValue) {
      this.calendarPeriod = moment(newValue).format('YYYY-MM')
      this.openCalendar = false
      this.getPaymentListContrat()
    }
  },
  methods: {
    handlePressCalendar() {
      this.openCalendar = !this.openCalendar
    },
    async downloadDocumentPaymentList(value){
     const a = document.createElement('a')
      a.style.display = 'none'
      a.href = getDocumentLinkWithId(value.document_id)
      a.download = value.document_name
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(getDocumentLinkWithId(value.document_id))
    },
    getPaymentListContrat(){
      this.$http.get(this.contract_list_payment + this.calendarPeriod + '&page=1'+'&per_page=20').then((response)=>{
        console.log("this is the response",response.data)
        this.paymentListData = response.data.data.map((item,index) =>({company_name:item.company.company_name,company_shortname:item.company.company_shortname,document:item.document,customergroup_name:item.company.customergroup.customergroup_name}))
      }).catch((error)=>{
        console.error(error)
      })
    }
  },
  mounted() {
    this.getPaymentListContrat()
  }
}
</script>

<template>
  <div class="position-relative">
    <DataTable :columns="columnsRow"
               :bar-actions="[{label:'',icon:'CalendarIcon',onClick:handlePressCalendar},{label:calendarPeriod.toString()}]"
               :dataTable="paymentListData"
               :custom-actions="[{icon:'DownloadIcon',onClick:(item)=>downloadDocumentPaymentList({document_id:item.document.document_id}), label:'Download payment list'}]"
               />
    <date-picker placeholder="Filter by date" v-model="calendarPeriod" class="position-absolute"
                 style="top:10px;right: 0;z-index: -1" :open="openCalendar" :range="false" type="month"></date-picker>
  </div>
</template>

<style scoped>

</style>
