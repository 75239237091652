<template>
  <div class="text-capitalize">
    <div class="d-flex align-items-center mb-2" style="gap: 8px;">
      <b-button :variant="currentTab === 'board' ? 'primary' : 'light'" @click="currentTab = 'board'">
        {{ $t('dashboard~board~stats') }}
      </b-button>
      <b-button :variant="currentTab === 'column' ? 'primary' : 'light'" @click="currentTab = 'column'">
        {{ $t('dashboard~columns~stats') }}
      </b-button>
      <b-button :variant="currentTab === 'paylist' ? 'primary' : 'light'" @click="currentTab = 'paylist'">
        {{ $t('dashboard~payment~list') }}
      </b-button>
    </div>
    <div v-show="currentTab === 'board'">
      <b-row class="align-items-center">
        <b-col>
          <h2>{{ $t('dashboard~board~stats') }}</h2>
        </b-col>
        <b-col sm="9">
          <b-row>
            <b-col
              v-for="field in boardFilters"
              :key="`columns-filter-${field.key}`"
              class="px-50"
              :sm="12 / Math.ceil(boardFilters.length)"
            >
              <field
                class="ml-sm-1 w-100"
                :field="field"
                :entity="entity"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="isBoardStatsLoading" class="d-flex align-items-center justify-content-center p-5">
        <b-spinner />
      </div>
      <div v-else-if="boardStats.length === 0">
        <no-data
          :title="$t('board~stats~no~data~select~filters')"
          :description="$t('board~stats~no~data~select~filters~description')"
        />
      </div>
      <b-row v-else>
        <b-col sm="12">
          <b-card v-if="!!bestBoard">
            <div class="d-flex justify-content-around w-100 flex-wrap" style="gap: 12px">
              <div class="d-flex flex-column justify-content-around">
                <h4>{{ $t('dashboard~board~global~stats') }}</h4>
                <div class="d-flex" style="gap: 12px">
                  <BoardGlobalStatItem
                    icon="ThumbsUpIcon"
                    :title="$t('dashboard~board~respect~board~of~rdv')"
                    :value="totalInTime"
                    :progress="5"
                    class-name="bg-success"
                    :on-click="() => $router.push('/test')"
                  />
                  <BoardGlobalStatItem
                    icon="LoaderIcon"
                    :title="$t('dashboard~board~pending')"
                    :value="totalPending"
                    :progress="5"
                    class-name="bg-warning"
                    :on-click="() => $router.push('/test')"
                  />
                  <BoardGlobalStatItem
                    icon="FrownIcon"
                    :title="$t('dashboard~board~failed')"
                    :value="totalLate"
                    :progress="-5"
                    class-name="bg-danger"
                    :on-click="() => $router.push('/test')"
                  />
                </div>
                <div class="d-flex justify-content-between flex-wrap" style="gap: 8px;">
                  <p class="clamp-2" style="max-width: 220px">
                    <b><feather-icon class="text-success" icon="AwardIcon" /> {{ $t('dashboard~board~best~board') }}</b>: {{ bestBoard && bestBoard.board_name }}
                  </p>
                  <p class="clamp-2" style="max-width: 220px">
                    <b><feather-icon class="text-danger" icon="ThumbsDownIcon" /> {{ $t('dashboard~board~worst~board') }}</b>: {{ worstBoard && worstBoard.board_name }}
                  </p>
                </div>
              </div>
              <board-pie-chart
                :series="[totalInTime, totalPending, totalLate]"
                :colors="['var(--success)', 'var(--warning)', 'var(--danger)']"
                :labels="[$t('dashboard~board~in~time'), $t('dashboard~board~pending'), $t('dashboard~board~late')]"
              />
            </div>
          </b-card>
        </b-col>
        <b-col
          v-for="stat in boardStats"
          :key="`stat-item-board-${stat.board_name}-${stat.board_id}`"
          sm="12"
          md="6"
          lg="4"
        >
          <board-stat-item :stat="stat" />
        </b-col>
      </b-row>
    </div>
    <div v-show="currentTab === 'column'">
      <b-row class="align-items-center">
        <b-col>
          <h2>{{ $t('dashboard~columns~stats') }}</h2>
        </b-col>
        <b-col sm="4">
          <b-row>
            <b-col
              v-for="field in columnsFilters"
              :key="`columns-filter-${field.key}`"
              class="px-50"
            >
              <field
                class="ml-sm-1 w-100"
                :field="field"
                :entity="entity"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="isColumnsStatsLoading" class="d-flex align-items-center justify-content-center p-5">
        <b-spinner />
      </div>
      <div v-else-if="columnsStats.length === 0 || !canGetColumnsData">
        <no-data
          :title="$t('board~stats~no~data~select~filters')"
          :description="$t('board~stats~no~data~select~filters~description')"
        />
      </div>
      <b-row v-else>
        <b-col
          v-for="stat in columnsStats"
          :key="`stat-item-column-${stat.column_name}-${stat.column_id}`"
          sm="12"
          md="6"
          lg="4"
        >
          <board-stat-item :stat="stat" />
        </b-col>
      </b-row>
    </div>
    <div v-if="currentTab === 'paylist'">
      <DasboardPaymentList />
    </div>
  </div>
</template>

<script>
import NoData from '@/views/app/CustomComponents/NoData/NoData.vue'
import Field from '../Generic/Field.vue'
import { datePickerOptions } from './data'
import BoardStatItem from './widgets/BoardStatItem.vue'
import BoardGlobalStatItem from './widgets/BoardGlobalStatItem.vue'
import BoardPieChart from './widgets/BoardPieChart.vue'
import DasboardPaymentList from "@/views/app/DashboardBoard/widgets/DasboardPaymentList.vue";

const columnsFilters = [
  {
    key: 'board_id',
    type: 'list',
    list: 'board',
    listLabel: 'board_name',
    required: false,
  },
]

const boardFilters = [

  {
    key: 'customergroup_id',
    type: 'list',
    list: 'customergroup',
    listLabel: 'customergroup_name',
    required: false,
  },
  {
    key: 'company_id',
    type: 'list',
    list: 'frontend_2_2_3_1',
    listLabel: 'company_name',
    filter_key: 'customergroup_id',
    required: false,
  },
  {
    key: 'pos_id',
    type: 'list',
    list: 'frontend_2_1_3_8',
    listLabel: 'pos_name',
    filter_key: 'company_id',
    required: false,
  },
]

export default {
  name: 'DashboardBoard',
  components: {
    DasboardPaymentList,
    BoardStatItem,
    Field,
    NoData,
    BoardGlobalStatItem,
    BoardPieChart,
  },
  data() {
    return {
      entity: {
        board_id: '',
        customergroup_id: '',
        company_id: '',
        pos_id: '',
      },
      date: '',
      datePickerOptions,
      columnsFilters,
      boardFilters,
      isBoardStatsLoading: false,
      isColumnsStatsLoading: false,
      boardStats: [],
      columnsStats: [],
      currentTab: 'board',
    }
  },
  computed: {
    canGetColumnsData() { return this.entity.board_id },
    totalPending() { return this.boardStats?.reduce((a, c) => a + c.board_stats.pending, 0) },
    totalLate() { return this.boardStats?.reduce((a, c) => a + c.board_stats.late, 0) },
    totalInTime() { return this.boardStats?.reduce((a, c) => a + c.board_stats.in_time, 0) },
    total() { return this.totalPending + this.totalLate + this.totalInTime },
    bestBoard() { return JSON.parse(JSON.stringify(this.boardStats ?? [])).sort((b1, b2) => (this.getPercentage(b1.board_stats.in_time) > this.getPercentage(b2.board_stats.in_time) ? -1 : 1))[0] },
    worstBoard() { return JSON.parse(JSON.stringify(this.boardStats ?? [])).sort((b1, b2) => (this.getPercentage(b1.board_stats.late) > this.getPercentage(b2.board_stats.late) ? -1 : 1))[0] },
  },
  watch: {
    'entity.board_id': function (board_id) {
      if (!this.canGetColumnsData) return

      this.getColumnsStats(board_id)
    },
    'entity.company_id': function (company_id) {
      this.entity.pos_id = ''
      this.getBoardStats({
        company_id,
        pos_id: this.entity.pos_id,
        customergroup_id: this.entity.customergroup_id,
      })
    },
    'entity.pos_id': function (pos_id) {
      this.getBoardStats({
        pos_id,
        company_id: this.entity.company_id,
        customergroup_id: this.entity.customergroup_id,
      })
    },
    'entity.customergroup_id': function (customergroup_id) {
      this.entity.company_id = ''
      this.getBoardStats({
        customergroup_id,
        company_id: this.entity.company_id,
        pos_id: this.entity.pos_id,
      })
    },
  },
  mounted() {
    this.getBoardStats()
  },
  methods: {
    getPercentage(inTime) { return Number((inTime * 100) / ((this.total) || 1)) },
    async getBoardStats(payload) {
      const url = '/tickets/statistics/timing/board'
      try {
        this.isBoardStatsLoading = true
        const params = {}
        Object.keys(payload || {}).forEach(key => {
          if (payload[key] || payload[key] === 0) {
            params[key] = payload[key]
          }
        })
        const { data } = await this.$http.get(url, {
          params,
        })
        this.boardStats = data
      } catch (e) {
        this.$errorToast('Error while loading board stats data')
      } finally {
        this.isBoardStatsLoading = false
      }
    },
    async getColumnsStats(board_id) {
      if (!board_id) {
        this.$errorToast('Please select a board')
        return
      }

      const url = `/tickets/statistics/timing/board/${board_id}`
      try {
        this.isColumnsStatsLoading = true
        const { data } = await this.$http.get(url)
        this.columnsStats = data
      } catch (e) {
        this.$errorToast(e.message ?? 'Error while loading board stats data')
      } finally {
        this.isColumnsStatsLoading = false
      }
    },
  },
}
</script>
