<template>
  <b-card class="w-100">
    <div class="d-flex align-items-start justify-content-between">
      <strong :title="name" class="clamp-2" style="height: 40px"><feather-icon style="margin-top: -2px;" size="18" :icon="isColumn ? 'ClipboardIcon' : 'LayoutIcon'" /> {{ name }}</strong>
      <div v-if="stat && stat.board_id">
        <b-dropdown
          class="ml-50"
          size="sm"
          variant="light"
          no-caret
          dropleft
        >
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="14" />
          </template>
          <b-dropdown-item @click="$router.push(`/app/table/board/${stat.board_id}/kanban`)">
            {{ $t('dashboard~board~open~the~board') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <h2 class="mt-1 h1">
      {{ inTime + pending + late }} <small class="text-muted">{{ $t('dashboard~board~total~tasks') }}</small>
    </h2>
    <div class="mt-2 d-flex flex-column" style="gap: 10px;">
      <div>
        <div>
          <div class="d-flex justify-content-end mb-50">
            <strong class="h4">{{ percentageInTime }}% {{ $t('dashboard~board~in~time') }}</strong>
          </div>
          <div class="w-100 position-relative" style="padding: 4px">
            <div v-b-tooltip.hover :title="`${$t('dashboard~board~health')} ${Number(percentageHealth)}%`" class="rounded-pill position-absolute bg-success" :style="`width: ${Number(percentageHealth)}%; top: 0; left: 0; bottom: 0; box-shadow: 0px 0px 4px var(--success);`" />
            <div v-b-tooltip.hover :title="`${Number(percentagePending)}% ${$t('dashboard~board~pending')}`" class="rounded-pill position-absolute bg-warning" :style="`width: ${Number(percentagePending)}%; top: 0; left: ${Number(percentageHealth)}%; bottom: 0; box-shadow: 0px 0px 4px var(--warning);`" />
            <div v-b-tooltip.hover :title="`${Number(percentageLate)}% ${$t('dashboard~board~late')}`" class="rounded-pill position-absolute bg-danger" :style="`width: ${Number(percentageLate)}%; top: 0; left: ${Number(percentageHealth) + Number(percentagePending)}%; bottom: 0; box-shadow: 0px 0px 4px var(--danger);`" />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div>
            <div class="bg-success rounded-pill d-inline-block" style="width: 6px; height: 6px;" /> <small>{{ inTime }} {{ $t('dashboard~board~in~time') }}</small>
          </div>
          <div>
            <div class="bg-warning rounded-pill d-inline-block" style="width: 6px; height: 6px;" /> <small>{{ pending }} {{ $t('dashboard~board~pending') }}</small>
          </div>
          <div>
            <div class="bg-danger rounded-pill d-inline-block" style="width: 6px; height: 6px;" /> <small>{{ late }} {{ $t('dashboard~board~late') }}</small>
          </div>
        </div>
        <div class="text-center">
          <feather-icon v-if="stat && stat.column_is_qualitygate" icon="StarIcon" class="text-warning" size="24" />
          <p v-if="stat && stat.column_type" class="text-capitalize mt-50 text-sm" style="transform: rotate(-10deg); font-style: italic; font-family: cursive;">
            {{ $t(`dashboard~board~${stat.column_type}`) }}
          </p>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  name: 'BoardStatItem',
  components: {
    BDropdown,
    BDropdownItem,
  },
  props: {
    stat: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    isColumn() { return !!this.stat?.column_id },
    name() { return this.isColumn ? this.stat?.column_name : this.stat.board_name },
    inTime() { return this.isColumn ? this.stat?.column_stats?.in_time ?? 0 : this.stat?.board_stats?.in_time ?? 0 },
    pending() { return this.isColumn ? this.stat?.column_stats?.pending ?? 0 : this.stat?.board_stats?.pending ?? 0 },
    late() { return this.isColumn ? this.stat?.column_stats?.late ?? 0 : this.stat?.board_stats?.late ?? 0 },
    percentageInTime() { return Number((this.inTime * 100) / ((this.pending + this.late + this.inTime) || 1)).toFixed(1) },
    percentagePending() { return Number((this.pending * 100) / ((this.pending + this.late + this.inTime) || 1)).toFixed(1) },
    percentageLate() { return Number((this.late * 100) / ((this.pending + this.late + this.inTime) || 1)).toFixed(1) },
    percentageHealth() { return 100 - Number(this.percentagePending) - Number(this.percentageLate) },
  },
}
</script>
