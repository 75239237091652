<template>
  <div class="d-flex align-items-center cursor-pointer" style="gap: 12px">
    <div class="rounded-lg p-1 d-flex justify-content-center align-items-center badge-success" :class="className">
      <feather-icon size="24" :icon="icon" />
    </div>
    <div class="d-flex flex-column">
      <small :title="title" class="d-inline-block text-truncate" style="max-width: 50px;">{{ title }}</small>
      <h2>{{ value }}
        <!-- <small :class="isNegative ? 'text-danger' : 'text-success'">
          {{ isNegative ? '' : '+' }}{{ progress }}% <feather-icon :icon="isNegative ? 'TrendingDownIcon' : 'TrendingUpIcon'" />
        </small> -->
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoardGlobalStatItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
    onClick: {
      type: Function,
      default: () => null,
    },
  },
  computed: {
    isNegative() { return this.progress < 0 },
  },
}
</script>
